@import '../../../styles/variables.module.scss';

.roadmapFilterHeader {
  align-items: center;
  &.withoutPopoverWrapper {
    display: none;

    @include small-screen() {
      display: flex;
    }
  }
  .requestFeatureIconButton {
    margin-left: 6px;
    height: 36px !important;
    width: 36px !important;
  }

}

.mobile {
  .roadmapFilterHeader {
    padding-top: 3px;

    @include small-screen() {
      padding-top: 15px;
    }
    .requestFeatureIconButton {
      margin-top: 14px;
    }
  }
}
