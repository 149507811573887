@import '../../../../styles/variables.module.scss';

.sortAndFilterModalHeader {
  @include Header4Bold();
  color: var(--wds-color-black-100) !important;
}
.mobileSortAndFilterMenu {
  display: none !important;

  @include mobile-only() {
    display: flex !important;
  }
  @include small-screen() {
    display: flex !important;
  }
  .mobileSortIconWrapper {
    position: relative;
  }
  .counterBadgeWrapper {
    position: absolute;
    top: -11px;
    left: 19px;
    pointer-events: none;
    &.mobile {
      @include small-screen() {
        top: -4px;
        left: 19px;
      }
    }
    @include small-screen() {
      top: -15px;
      left: 24px;
    }
  }

  .modalIconButton {
    border-color: #d6e6fe !important;
    height: 36px !important;
    width: 36px !important;
    &.mobile {
      @include small-screen() {
        display: flex;
        visibility: visible;
      }

      @include mobile-landscape() {
        width: 34px;
        height: 34px;
      }
    }
    @include small-screen() {
      display: none;
      visibility: hidden;
    }
  }
}
.filterAndSortModalBody {
  &.mobile {
    .selectProductWrapper {
      padding: var(--wds-space-300) 0 var(--wds-space-500);
      @include small-screen() {
        display: flex;
        visibility: visible;
      }
    }

    .resolutionMenu {
      @include small-screen() {
        display: flex;
        visibility: visible;
      }
    }
  }
  width: 354px;
  @include small-screen() {
    width: 400px;
    height: unset !important;
  }
  .selectProductWrapper {
    padding: var(--wds-space-300) 0 var(--wds-space-500);
    @include small-screen() {
      display: none;
      visibility: hidden;
    }
  }

  .resolutionMenu {
    @include small-screen() {
      display: none;
      visibility: hidden;
    }
  }
  min-width: 320px !important;
  .sortHeader,
  .filterHeader,
  .resolutionHeader {
    font-size: 14px !important;
    color: var(--wds-color-black-100) !important;
    padding-bottom: var(--wds-space-200);
  }
  [data-hook='Radio-option-container'] {
    [data-hook='radio-icon'] {
      border-color: var(--wds-color-blue-100) !important;
    }
    [data-checked='true'] {
      [data-hook='radio-icon']::after {
        background-color: var(--wds-color-blue-100) !important;
      }
    }
  }

  [data-hook='baseModalLayout-primary-button'] {
    background-color: var(--wds-color-blue-100) !important;
    border-color: var(--wds-color-blue-100) !important;
  }
}
