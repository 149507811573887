@import '../../../styles/variables.module.scss';
.emptyState {
  min-width: 820px;
  padding: 138px 0 156px;
  @include mobile-only() {
    min-height: 472px;
    min-width: 100%;
    width: 100%;
    padding-top: 32px;
  }
  .loginButton {
    padding-right: 3px;
    font-weight: 700 !important;
    font-size: 18px !important;
  }

  &.roadmapV2 {
    @include small-screen() {
      margin: 72px 0 84px;
      padding: unset;
      min-height: unset;
      min-width: unset;
    }
    @include mobile-only() {
      min-height: unset;
      min-width: 100%;
      width: 100%;
      padding: 42px 0;
    }
  }

  @include small-screen() {
    min-width: unset;
  }
  .emptyStateHeader {
    color: var(--wds-color-black-100) !important;
    padding-bottom: 6px;
    @include Header5Bold();
    @include mobile-only() {
      text-align: center;
    }
  }
  .emptyStateSubHeaderFirstRow,
  .emptyStateSubHeaderSecRow {
    color: var(--wds-color-black-100) !important;
    text-align: center;
    width: 80%;
    @include mobile-only() {
      text-align: center;
      width: 100%;
    }
  }
}
