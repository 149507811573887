@import '../../../styles/variables.module.scss';
.roadmapSortAndFilter {
  border: 1px solid var(--wds-color-black-600);
  border-radius: 12px;
  padding: var(--wds-space-400);
  width: 100%;
  &.scrolled {
    border-bottom: unset;
    border-radius: 12px 12px 0 0;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 10%);
    @include mobile-only() {
      border-radius: unset;
      box-shadow: unset;
    }
  }
  &.mobile {
    border: unset;
    padding: unset;
    border-radius: unset;
    width: unset;
    &.scrolled {
      @include small-screen() {
        border-radius: unset;
        box-shadow: unset;
        padding-top: 14px;
      }
    }
    @include small-screen() {
      width: unset;
    }
  }
  @include mobile-only() {
    border: unset;
    padding: unset;
    border-radius: unset;
  }
  @include small-screen() {
    width: 100%;
  }
  [data-hook='roadmap-select-products'],
  [data-hook='roadmap-select-products-wrapper'] {
    max-width: 270px;
    @include small-screen() {
      max-width: unset;
    }
  }
  [data-hook='roadmap-filter-menu-wrapper'],
  [data-hook='roadmap-sort-menu-wrapper'] {
    @include small-screen() {
      display: none !important;
    }
  }
  .selectProduct {
    max-width: 270;
    width: 100%;
  }
  .mobileSortAndFilterMenuWrapper {
    display: none;
    &.mobile {
      @include small-screen() {
        display: flex;
        justify-content: end;
        padding-top: unset;
      }
    }

    @include small-screen() {
      padding-top: 20px;
      display: flex;
      height: fit-content;
      align-self: center;
    }
    @include mobile-only() {
      display: flex;
      justify-content: end;
    }
  }
}
