@import '../../styles/variables.module.scss';

.wrapper {
  height: 54px;
  padding-inline-start: var(--wds-space-400);
  padding-inline-end: var(--wds-space-600);
  border-bottom: 1px solid var(--wds-color-black-700);
  max-width: 100%;
  position: sticky;
  top: calc(var(--sub-header-size) + var(--agent-toolbar-size));
  background-color: var(--wds-color-white);
  z-index: 3;

  ol {
    padding-inline-start: unset;
  }

  .menu {
    left: 0;
    z-index: 2;
    display: none;
    position: relative;

    .menuButton {
      display: flex;
      align-items: center;
      cursor: pointer;
      width: fit-content;
    }
  }

  .breadcrumbs {
    display: flex;
    align-items: center;
    max-width: 100%;
    height: 100%;
  }

  @mixin withMenu() {
    .menu {
      display: block;
      background-color: var(--wds-color-white);
      display: flex;
      align-items: center;
      box-sizing: border-box;

      .divider {
        margin-inline-start: 13px;
        height: 24px;
      }
    }

    .breadcrumbs {
      padding-inline-end: 40px;
      overflow-x: auto !important;

      &.withMenu {
        padding-inline-start: 8px;
      }
    }

    .scrollableIndicator {
      position: absolute;
      box-sizing: content-box;
      right: 0;
      content: '';
      width: 45px;
      height: 42px;
      z-index: 2;
      background: var(--wds-color-gradient-light-left-to-right);
      transform: rotate(-180deg);

      @include rtl() {
        left: 0;
        right: auto;
        background: var(--wds-color-gradient-light-right-to-left);
        transform: rotate(-90deg);
      }
    }
  }

  @include small-screen {
    height: 43px;
    padding-inline-end: 0px;
    padding-inline-start: var(--wds-space-300);

    .breadcrumbs {
      padding-inline-end: var(--wds-space-500);

      @include small-screen() {
        padding-inline-end: var(--wds-space-400);
      }

      @include mobile-only() {
        padding-inline-end: var(--wds-space-300);
      }
    }

    .scrollableIndicator {
      width: var(--wds-space-400);
      height: var(--wds-space-300);
    }

    @include withMenu();
  }

  @include mobile-only {
    height: 43px;
    padding-inline-start: var(--wds-space-300);
    padding-inline-end: 0px;

    &.newSubheaderDesign {
      padding-inline-start: var(--wds-space-200);
      padding-inline-end: var(--wds-space-300);

      .breadcrumbs {
        padding-inline-end: var(--wds-space-500);

        @include small-screen() {
          padding-inline-end: var(--wds-space-400);
        }

        @include mobile-only() {
          padding-inline-end: var(--wds-space-300);
        }
      }

      .scrollableIndicator {
        width: var(--wds-space-400);
        height: var(--wds-space-300);
        right: 17px;

        @include rtl() {
          left: 17px;
          right: auto;
        }
      }
    }

    @include withMenu();
  }
}
