@import './../../../styles/variables.module.scss';

.articleWrapper {
  padding: var(--wds-space-400);
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;

  @include mobile-only() {
    padding: var(--wds-space-200) 0 var(--wds-space-300) 0;
    position: unset !important;
  }

  .articleLeftPartWrapper {
    padding-right: 90px;
    flex-grow: 1;
    max-width: 800px;
    @include mobile-only() {
      padding-right: 0;
      max-width: unset;
    }
    @include small-screen() {
      padding-right: var(--wds-space-600);
      max-width: unset;
    }
    .articleTextPart {
      text-decoration: none !important;
    }
  }
  .articleContentWrapper {
    padding: 6px 0 18px;
    @include mobile-only() {
      padding: 6px 0 var(--wds-space-200);
    }
  }
  .articleTitle {
    color: var(--wds-color-black-100) !important;
    font-size: 18px !important;
    &:hover {
      color: var(--wds-color-blue-50) !important;
    }
    @include mobile-only() {
      font-size: 16px !important;
    }
  }
  .articleContent {
    overflow: hidden;
    text-overflow: ellipsis;
    height: 100%;
    display: -webkit-box;
    line-height: 24px !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: var(--wds-color-black-100) !important;
    font-size: 16px !important;
    @include mobile-only() {
      font-size: 14px !important;
      line-height: 18px !important;
    }
    &:hover {
      color: var(--wds-color-blue-50) !important;
    }
  }
  .followArticle {
    max-width: min-content;
  }

  .voteContent {
    text-align: center;
    padding-top: var(--wds-space-100);
    color: var(--wds-color-black-100) !important;
  }
  .labelsWrapper {
    padding-bottom: var(--wds-space-300);
    @include mobile-only() {
      padding-bottom: var(--wds-space-100);
    }
    .LabelNameWrapper {
      padding-right: 3px;
      color: var(--wds-color-black-400) !important;
      &:hover {
        color: var(--wds-color-blue-100) !important;
        @include mobile-only() {
          color: unset !important;
          text-decoration: unset !important;
        }
      }
    }
  }
}
