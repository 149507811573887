@import '../../styles/variables.module.scss';

.wrapper {
  background-color: var(--wds-color-white);
  padding: 120px 0;
  @include mobile-only() {
    padding: var(--wds-space-1000) 0;
  }
  .innerWrapper {
    width: 609px;
    @include mobile-only() {
      width: 100%;
      padding: 0 28px;
      box-sizing: border-box;
    }

    span.title {
      @include Header1Bold();
      color: var(--wds-color-black-100);
      text-align: center;
      white-space: pre-wrap;

      @include mobile-only() {
        @include Header3Bold();
      }
    }
    span.subtitle {
      color: var(--wds-color-black-200);
      margin: var(--wds-space-500) 0;
      text-align: center;
      @include mobile-only() {
        margin: var(--wds-space-300) 0 var(--wds-space-500) 0;
      }
    }
    .ctaWrapper {
      gap: var(--wds-space-200);
      max-width: 100%;
      @include mobile-only() {
        flex-direction: column;
        gap: var(--wds-space-300);
      }
      a {
        text-decoration: none;
        color: inherit;
        button {
          width: 100%;
        }
      }
    }
  }
}
