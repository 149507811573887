@import '../../styles/variables.module.scss';

.wrapper {
  width: 100%;
  z-index: 4;
  box-sizing: border-box;
  justify-content: space-between;
  position: sticky;
  top: var(--agent-toolbar-size);
  height: 78px;

  .subHeaderWrapper {
    padding: var(--wds-space-300) var(--wds-space-400);
    padding-inline-start: var(--wds-space-500);

    @include small-screen() {
      padding-inline-start: var(--wds-space-400);
    }

    @include mobile-only() {
      padding: var(--wds-space-200) var(--wds-space-300);
      padding-inline-start: var(--wds-space-300);
    }
  }

  @include mobile-only() {
    height: 68px;
    justify-content: center;
  }

  .backToHomepageLink {
    position: relative;
    z-index: 2;
    @include Header3Bold();

    @include small-screen() {
      @include Header4Bold();
    }

    @include mobile-only() {
      display: none;
    }

    a {
      text-decoration: none;
      color: var(--wds-color-white);

      &:hover {
        color: var(--wds-color-white);
      }
    }
  }
}
