.anchors {
  @import './ScrollSpy/index.scss';

  .title {
    color: $D20 !important;
  }

  .scrollToTop {
    position: fixed;
    bottom: 18px;
    right: 18px;
    z-index: 3;
    box-shadow: 0px 0px 6px 0px #162d3d26;

    @include rtl() {
      right: unset;
      left: 18px;
    }
  }
}