@import '../../styles/variables.module.scss';

.viewer-user-toolbar-container {
  position: sticky;
  z-index: 99999;

  .aul {
    font-family: inherit;
    font-size: 12px;
  }
}

.aul.tooltip-trigger,
.aul.popper-portal-dest {
  font-size: inherit;

  .tooltip {
    top: 0;

    min-width: 30px;
    max-width: 340px;
    white-space: normal;
    background: var(--wds-color-black-200);
    color: #dfe5eb;
    border-radius: 4px;
    font-size: 12px;
    font-weight: normal;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
    padding: var(--wds-space-200);
    user-select: none;
    display: inline-block;
    z-index: 999999;
    text-align: center;

    .tooltip-body {
      &.left {
        text-align: left;
      }

      &.right {
        text-align: right;
      }

      &.center {
        text-align: center;
      }
    }
  }

  .popper-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 10px;
  }

  .tooltip[data-placement^='top'] {
    margin-bottom: 5px;

    .popper-arrow {
      border-width: 5px 5px 0 5px;
      border-color: #2f2f30 transparent transparent transparent;
      bottom: -15px;
    }
  }

  .tooltip[data-placement^='bottom'] {
    margin-top: 5px;

    .popper-arrow {
      border-width: 0 5px 5px 5px;
      border-color: transparent transparent #2f2f30 transparent;
      top: -15px;
    }
  }

  .tooltip[data-placement^='right'] {
    margin-left: 5px;

    .popper-arrow {
      border-width: 5px 5px 5px 0;
      border-color: transparent #2f2f30 transparent transparent;
      left: -15px;
    }
  }

  .tooltip[data-placement^='left'] {
    margin-right: 5px;

    .popper-arrow {
      border-width: 5px 0 5px 5px;
      border-color: transparent transparent transparent var(--wds-color-black-200);
      right: -15px;
    }
  }
}

.aul.Popover.article-popover {
  z-index: 999999;
  font-family: inherit;

  .Popover-tipShape {
    fill: #ffffff;
  }

  .article-stats-body {
    padding: 20px 0px;

    hr {
      margin: 15px 0;
    }

    .times-container {
      padding: 0 23px;
    }

    .counters-container {
      border-top: solid 1px #e6e6e6;
      padding: 0 10px;
      margin-top: var(--wds-space-300);
      white-space: nowrap;
    }

    .agent-times {
      margin-bottom: 10px;

      .time-label {
        display: inline-block;
        width: 80px;
        margin-right: 5px;
        color: #b6c1cd;
      }
    }

    .counter-element {
      display: inline-block;
      text-align: center;
      margin: 15px 15px 0;
      text-decoration: none;

      &.helpful .count-value {
        color: #6de18e;
      }

      &.unhelpful .count-value {
        color: #e6223c;
      }

      .count-label {
        font-size: 12px;
        color: #b6c1cd;
      }

      .count-value {
        font-size: 18px;
        font-weight: 500;
        display: block;
      }
    }
  }
}

.Popover-body {
  background: #fff;
  max-height: calc(100vh - 40px);
  border-radius: 4px;
  box-shadow: 0 0 20px 2px rgb(0 0 0 / 15%);
  padding: 0;

  .linked-tickets {
    .count-value {
      color: var(--wds-color-black-100);
    }
  }
}

.user-toolbar-container {
  border-bottom: 1px solid var(--wds-color-white);
  font-family: inherit;
  display: block;
  position: fixed;
  z-index: 999999;
  left: 0;
  right: 0;
  top: 0;

  .toggle-expansion {
    border-bottom: 1px solid var(--wds-color-white);
  }
}

.follow-category,
.follow-article {
  .checkbox {
    display: inline-flex;
    -ms-flex-align: center;
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    cursor: pointer;

    .check-icon {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: center;
      justify-content: center;
      -ms-flex-align: center;
      align-items: center;
      background-color: #fff;
      position: relative;
      border-radius: 4px;
      display: inline-block;
      border: 1px solid #3899ec;
      width: 13px;
      height: 13px;
      transition: background-color 0.1s linear, border 0.1s linear;
    }

    .checkbox-body {
      padding-left: var(--wds-space-100);
      font-size: 12px;
    }
  }

  .checked {
    .check-icon {
      background-color: #3899ec;
    }
  }
}

body .user-toolbar-container {
  background-color: var(--wds-color-black-100) !important;
}

body .user-toolbar-container .toggle-expansion {
  background-color: var(--wds-color-black-100) !important;
}

.user-toolbar-container {
  font-weight: 500 !important;
}

.agent-toolbar-close {
  header > div {
    transition: all 200ms;
  }
}
