@import '../../../styles/variables.module.scss';

.searchBarResults {
  overflow: hidden;
  border-radius: inherit;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding-top: var(--wds-space-100);

  @include mobile-only() {
    [data-input-parent='true'] {
      > div {
        height: 52px;
      }
    }
  }

  .resultsGroupTitle {
    background-color: #eceff3;
  }

  .searchBarResult {
    overflow: hidden;
    text-decoration: none;
    box-sizing: border-box;
    max-width: 100%;
    padding: 9px var(--wds-space-400);

    &.focused {
      background-color: var(--wds-color-blue-500);
    }

    &:hover,
    &:focus {
      background-color: var(--wds-color-blue-500);
    }

    .searchBarResultText {
      overflow: hidden;
      text-decoration: none;
      box-sizing: border-box;
      max-width: 100%;
      margin-inline-end: var(--wds-space-100);

      .searchBarResultTitle {
        color: var(--wds-color-black-100);
      }

      .searchBarResultDescription {
        color: var(--wds-color-black-400);
      }
    }

    .searchBarResultTag {
      flex-shrink: 0;

      > span {
        letter-spacing: 0.5px;
        margin-bottom: 1px;
      }
    }
  }

  .searchBarResultsFooter {
    padding: var(--wds-space-200) var(--wds-space-400);

    &.focused {
      .seeMoreResultsText {
        color: var(--wds-color-blue-200) !important;
      }
    }
  }
  .seeMoreResults {
    text-decoration: none;

    .seeMoreResultsText {
      color: var(--wds-color-blue-100) !important;

      &:hover {
        color: var(--wds-color-blue-200) !important;
      }
      &:focus {
        color: var(--wds-color-blue-200);
      }

      &:active {
        color: var(--wds-color-blue-50);
      }
    }
  }

  .srOnly {
    position: absolute;
    clip: rect(0 0 0 0);
  }
}
