@import '../../styles/variables.module.scss';

.roadmapResolutionEta {
  display: flex;
  align-items: center;
  gap: var(--wds-space-100);
  flex-shrink: 0;
  .etaTag {
    background-color: var(--wds-color-black-600) !important;

    .eta {
      color: var(--wds-color-black-100) !important;
    }
  }

  .etaBody,
  .statusBody {
    display: flex;
    align-items: center;

    .resolutionText {
      color: var(--wds-color-black-100) !important;
    }
  }

  .timeIcon {
    padding-right: 7px;
  }
}
