@import '../../styles/variables.module.scss';

.roadmapResolutionBadge {
  display: flex;

  .tooltip {
    display: flex;

    [data-hook~='popover-element'] {
      display: flex;
      align-items: center;
    }
  }

  .statusTag {
    color: var(--wds-color-black-100);

    &.collecting-votes {
      background-color: #e7f0ff !important;

      &:hover {
        background-color: var(--wds-color-blue-400) !important;
      }
    }

    &.planned {
      background-color: var(--wds-color-purple-400) !important;

      &:hover {
        background-color: var(--wds-color-purple-300) !important;
      }
    }

    &.released {
      background-color: var(--wds-color-green-400) !important;

      &:hover {
        background-color: var(--wds-color-green-300) !important;
      }
    }

    &.in-dev {
      background-color: var(--wds-color-yellow-400) !important;

      &:hover {
        background-color: var(--wds-color-yellow-300) !important;
      }
    }

    &.pre-launch {
      background-color: #ffc58d !important;

      &:hover {
        background-color: #fdb10c !important;
      }
    }

    &.clickable {
      &:hover {
        &.collecting-votes {
          background-color: var(--wds-color-blue-400) !important;
        }

        &.planned {
          background-color: var(--wds-color-purple-300) !important;
        }

        &.released {
          background-color: var(--wds-color-green-300) !important;
        }

        &.in-dev {
          background-color: var(--wds-color-yellow-300) !important;
        }

        &.pre-launch {
          background-color: #fdb10c !important;
        }
      }
    }
  }

  .etaTag {
    padding: 0 var(--wds-space-200);
    background-color: var(--wds-color-black-600) !important;

    .eta {
      color: var(--wds-color-black-100) !important;
    }
  }

  .etaBody,
  .statusBody {
    display: flex;
    align-items: center;

    .resolutionText {
      color: var(--wds-color-black-100) !important;
      white-space: nowrap;
    }
  }

  .timeIcon {
    padding-right: 7px;
  }
}
