@import '../../../styles/variables.module.scss';

.EmptySearchResults {
  max-width: 342px;
  height: 360px;
  box-sizing: border-box;
  margin: 0 auto;
  overflow: hidden;

  .header {
    text-align: center;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: var(--wds-space-100);
  }

  .message {
    width: 260px;
    text-align: center;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    color: var(--wds-color-black-200) !important;
  }
}
