@import '../../../styles/variables.module.scss';
.backgroundWrapper {
  background-color: var(--wds-color-white);
  height: 150px;
  border-bottom: solid 1px var(--wds-color-black-600);
  &.roadmapV2 {
    height: unset !important;
    border-bottom: unset;
    padding: var(--wds-space-700) 0 var(--wds-space-500) 0;
    &.scrolled {
      padding-bottom: var(--wds-space-400);
      padding: var(--wds-space-400) 0;
      margin: 0px -4px;
      .roadmapHeader {
        position: fixed;
        top: calc(var(--breadcrumbs-size) + var(--agent-toolbar-size));
        background-color: var(--wds-color-white);
        box-shadow: 0 6.3px 6.3px -6.3px rgba(0, 6, 36, 0.18);
        max-width: 944px;
        width: 100%;

        @include small-screen() {
          max-width: calc(100% - var(--wds-space-700));
        }
        @include mobile-only() {
          max-width: 100%;
        }
        .roadMapHeading {
          margin: var(--wds-space-400) 0;
          transition: all 0.5s ease-in-out;
          padding-bottom: 0;
          font-size: 20px !important;

          @include small-screen() {
            font-size: 20px !important;
          }
        }
      }
      .roadMapSubHeading {
        transition: all 0.7s ease-in-out;
      }
    }
    @include small-screen() {
      padding: var(--wds-space-100) 0 var(--wds-space-500) 0;
      height: unset !important;
      min-height: unset !important;
    }
  }
  &.midSize {
    border-radius: unset !important;
    height: 100px !important;
    &.roadmapV2 {
      &.isScrolled {
        @include small-screen() {
          padding: var(--wds-space-300) 0 var(--wds-space-500) 0;
        }
      }
      @include small-screen() {
        padding: var(--wds-space-100) 0 var(--wds-space-500) 0;
        height: unset !important;
        min-height: unset !important;
      }
    }

    .roadMapHeading {
      padding-bottom: 0;
    }

    @include mobile-only() {
      background-color: var(--wds-color-black-100);
      height: 60px !important;
      min-height: 60px !important;
    }
  }

  @include mobile-only() {
    background-color: var(--wds-color-black-100);
    height: 60px !important;
    min-height: 60px !important;
  }
  @include small-screen() {
    background-color: var(--wds-color-white);
    height: 100px !important;
    min-height: 100px !important;
  }
  img {
    @include small-screen() {
      height: 100px !important;
      min-height: 100px !important;
    }
  }
  .requestFeatureButton {
    align-self: center;
    flex-shrink: 0;
  }
}
.backgroundWrapperMobile {
  border-radius: unset !important;
  height: 60px !important;
  min-height: 60px !important;
}
.roadmapHeader {
  width: 100%;
  .roadMapHeading {
    padding-bottom: var(--wds-space-200);
    font-size: 32px !important;
    font-weight: 700 !important;
    @include Header2Bold();

    @include small-screen() {
      @include Header3Bold();
      padding-bottom: 0 !important;
      font-size: 24px !important;
    }
  }
  .roadMapSubHeading {
    font-weight: 500 !important;
    @include mobile-only() {
      display: none;
    }
    @include small-screen() {
      height: 100px !important;
      display: none;
    }
  }
}

.roadmapMobileHeader {
  width: 100%;
  box-sizing: border-box;
}

div.roadMapAutoEnrollmentNotification {
  width: 100%;
  max-width: 100%;
  margin-bottom: var(--wds-space-500);
}
