@import './variables.module.scss';

// Note: all scss for article viewer passed to -> https://github.com/wix-private/wix-answers/blob/master/answers-client/common/article-viewer/src/article-viewer/_wix-custom.scss & /src/components-viewer/_wix-custom.scss

.user-toolbar .user-toolbar-container {
  font-weight: 500 !important;
}

[class$='Overlay--after-open'] {
  background-color: var(--wds-color-black-100-transparent-50) !important;
}

div[role='tooltip'] {
  >div {
    @include rtl() {
      text-align: right !important;
      direction: rtl !important;
    }
  }
}
