@import '../../styles/variables.module.scss';

.articleList {
  width: 100%;
  padding: 0 100px var(--wds-space-400) 50px;
  border-left: 1px solid var(--wds-color-black-600);

  @include mobile-only() {
    padding: 0px var(--wds-space-300) var(--wds-space-200);
    border-left: none;
    width: auto;
  }

  .floatingNotificationEditorX {
    width: 100%;

    [data-hook='editor-x-notification'] {
      margin: 0 0 var(--wds-space-300);
      max-width: 100%;
      width: 100%;
      height: unset !important;
      padding: 6px 12px;

      @include mobile-only() {
        margin-top: 6px;
      }

      @include small-screen() {
        margin: var(--wds-space-300);
      }
    }
  }

  @include small-screen() {
    padding: var(--wds-space-400);
    border-left: none;
    width: unset !important;
  }

  .articleListInner {
    max-width: 936px;

    @include small-screen() {
      width: 100%;
      max-width: 100%;
    }

    @include mobile-only() {
      width: 100%;
      max-width: 100%;
    }
  }

  .headerWrapper {
    z-index: 9;
    background-color: var(--wds-color-white);

    &.mobile {
      .innerHeaderWrapper {
        padding-top: 3px;

        @include small-screen() {
          padding-top: 15px;
        }
      }

      .roadmapSearch {
        @include small-screen() {
          padding: var(--wds-space-200) var(--wds-space-100);
        }
      }
    }

    &.scrolled {
      .searchAndFiltersPanel {
        display: none;
        transition: all 0.7s ease-in-out;
      }

      &.mobile {
        @include small-screen() {
          .roadmapSearch {
            padding-top: var(--wds-space-200);
          }
        }
      }
    }

    .roadmapSearch {
      padding-bottom: var(--wds-space-400);

      @include mobile-only() {
        padding: 3px var(--wds-space-100) 3px 0;
      }
    }

    @include mobile-only() {
      padding: var(--wds-space-200) 0 0;
      top: calc(var(--breadcrumbs-size));
    }
  }

  .results {
    padding: var(--wds-space-400) 0 var(--wds-space-300);

    &.mobile {
      padding: 0 0 0;

      @include small-screen() {
        padding: var(--wds-space-100);
      }

      &.scrolled {
        box-shadow: 0 6px 3px 0 rgb(0 0 0 / 10%);
        padding: 0 var(--wds-space-300) var(--wds-space-200)
          var(--wds-space-300);
        margin: 0 -18px;

        @include small-screen() {
          margin: 0 -24px;
        }
      }
    }

    @include small-screen() {
      padding: var(--wds-space-300) 0 var(--wds-space-200);
    }

    .resultsText {
      font-size: 20px !important;
      line-height: 32px !important;

      @include mobile-only() {
        font-size: 12px !important;
        line-height: 15px !important;
      }

      @include small-screen {
        font-size: 16px !important;
        line-height: 24px !important;
      }
    }
  }

  .actionMenu {
    align-items: flex-end;

    @include mobile-only() {
      display: none;
    }

    @include small-screen() {
      display: none;
    }

    .roadmapFilterMenu {
      margin-left: var(--wds-space-200);
    }

    .roadmapSortMenu,
    .roadmapFilterMenu {
      max-width: 253px;
      border-color: #d6e6fe !important;
    }

    .roadmapSortMenu[data-focus='true'] {
      border-color: var(--wds-color-blue-100) !important;
    }

    .roadmapFilterMenu[data-focus='true'] {
      border-color: var(--wds-color-blue-100) !important;
    }

    [data-selected='true'] {
      [data-hook='dropdown-layout-option'] {
        color: var(--wds-color-white);
      }
    }

    [data-hook='dropdown-layout-option'] {
      color: var(--wds-color-black-100);
    }
  }

  .divider {
    height: 1px;
    border-bottom: solid 1px var(--wds-color-black-600);

    @include mobile-only() {
      margin-bottom: var(--wds-space-100);
    }
  }

  .articleListWrapper {
    border: 1px solid var(--wds-color-black-600);
    border-radius: 12px;

    @include mobile-only() {
      border: unset;
      border-radius: unset;
    }

    .emptyState {
      min-width: 820px;
      min-height: 486px;

      @include mobile-only() {
        min-height: 472px;
        min-width: 320px;
        width: 100%;
        padding-top: 32px;
      }

      .loginButton {
        padding-right: 3px;
        color: var(--wds-color-blue-100) !important;
      }

      @include small-screen() {
        min-width: unset;
        width: 100%;
      }

      .emptyStateHeader {
        color: var(--wds-color-black-100) !important;
        padding-bottom: 10px;
        @include Header5Bold();

        @include mobile-only() {
          text-align: center;
        }
      }

      .emptyStateSubHeaderFirstRow,
      .emptyStateSubHeaderSecRow {
        color: var(--wds-color-black-100) !important;
        text-align: center;
      }
    }
  }

  .sortMenuPrefix,
  .filterMenuPrefix {
    min-width: 67px;
    display: flex;
    align-items: center;
    padding-left: var(--wds-space-100);
    color: var(--wds-color-black-300) !important;
    cursor: pointer;
  }

  .filterMenuPrefix {
    min-width: 70px;
  }

  .articleResolutionTabs {
    @include mobile-only() {
      display: none;
    }

    @include small-screen() {
      display: none;
    }

    padding-left: var(--wds-space-100);

    [data-hook='article-resolution-tab'] {
      span {
        color: var(--wds-color-black-100) !important;
      }

      &:hover {
        span {
          color: var(--wds-color-blue-100) !important;
        }
      }

      &:active {
        span {
          color: var(--wds-color-blue-50);
        }
      }
    }

    [data-hook='article-resolution-tab-active'] {
      border-color: var(--wds-color-blue-100) !important;

      span {
        color: var(--wds-color-blue-100) !important;
      }
    }
  }

  .roadmapPagination {
    [data-hook*='current-page'] {
      background-color: var(--wds-color-blue-100) !important;
    }

    [data-hook='next'],
    [data-value='hook'] {
      color: var(--wds-color-blue-100) !important;

      a:hover {
        background-color: var(--wds-color-blue-100) !important;
        opacity: 0.8;
        color: white;
      }
    }

    [data-hook^='page-strip'] {
      a:hover {
        background-color: var(--wds-color-blue-100) !important;
        opacity: 0.8;
      }
    }
  }

  .noteMark {
    padding: var(--wds-space-500) 0;
    color: var(--wds-color-black-400) !important;

    @include mobile-only() {
      padding: var(--wds-space-500) var(--wds-space-200);
      text-align: center;
    }
  }
}

div.roadMapAutoEnrollmentNotification {
  width: 100vw !important;
  max-width: 100vw !important;
  height: 72px !important;

  span[data-hook='floating-notification-text'] {
    white-space: wrap !important;
  }
}
