@import '../../styles/variables.module.scss';

.roadmapPageWrapper {
  display: flex;
  min-height: 678px;

  @include mobile-only() {
    min-height: unset;
    flex-direction: column !important;
  }

  @include small-screen() {
    flex-direction: column !important;
    min-height: unset !important;
    justify-content: unset;
  }

  &.roadmapV2 {
    @include small-screen() {
      min-height: unset !important;
    }
  }
}

.breadcrumbsRoadmap {
  position: sticky;
  z-index: 10;
  top: var(--agent-toolbar-size);
}

.roadmapMobileBreadcrumbs {
  box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.05);
  padding: var(--wds-space-200) 8px;
  position: sticky;
  background-color: var(--wds-color-white);
  top: calc(var(--sub-header-size) + var(--agent-toolbar-size));
  z-index: 10;
}

.roadmapMobileHeader {
  width: 100%;
  box-sizing: border-box;

  .roadmapBreadcrumbs {
    color: var(--wds-color-black-100) !important;
    font-weight: 700 !important;
  }

  .helpcenterBreadcrumbs {
    color: var(--wds-color-black-100) !important;
  }
}
