@warn "Those variables are deprecated and should not be used anymore, we need to switch it to new WDS variables, ill take it with Laura";

$B20: #5999ff;
$B10: #116dff;
$B05: #0f62e6;

$D80: #ffffff;
$D50: #acafc4;
$D20: #333853;
$D10: #000624;

$B50: #e7f0ff;

$LINK_COLOR: $B10;
$LINK_COLOR_HOVER: $B20;

$DARK_LINK_COLOR: $D10;
$DARK_LINK_COLOR_HOVER: $B10;

$LINK_COLOR_ACTIVE: $B05;