@import '../../styles/variables.module.scss';

.wrapper {
  @include mobile-only() {
    width: 100%;
  }
}

.searchBarWrapper {
  position: relative;

  @include mobile-only() {
    [data-input-parent='true'] {
      > div {
        height: 52px;
      }
    }
  }
  .searchBar {
    > div > div > div {
      border-radius: 40px !important;
    }
    input {
      color: var(--wds-color-black-100);
    }
    ::placeholder {
      color: #868aa5;
    }
    width: 100%;
  }
  .searchBarResultsWrapper {
    z-index: 2;
    position: absolute !important;
    width: 100%;
  }

  .searchBarResultsWrapperHomepage {
    @extend .searchBarResultsWrapper;
    top: 63px;
  }

  .searchBarResultsWrapperSubHeader {
    @extend .searchBarResultsWrapper;
    top: 36px;

    @include mobile-only() {
      top: 52px;
    }
  }
}

.searchBarWrapperSubHeader {
  @extend .searchBarWrapper;
  width: 470px;
  @include mobile-only() {
    width: 100%;
  }

  &.newSubheaderDesign {
    width: 100%;
    @include desktop-only() {
      width: 470px;
    }

    @include small-screen() {
      width: 400px;
    }

    @include mobile-only() {
      width: calc(100% - var(--wds-space-600));
    }
  }
  .searchBar {
    [data-input-parent='true'] {
      > div {
        > div {
          padding: 0 var(--wds-space-200) !important;
        }
      }
    }

    ::placeholder {
      font-size: 16px;
    }
  }
}

.searchBarWrapperHomepage {
  @extend .searchBarWrapper;
  width: 680px;
  @include mobile-only() {
    width: 100%;
  }
  height: 60px;
  .searchBar {
    [data-input-parent='true'] {
      > div {
        height: 60px;
        > div {
          padding: 0 var(--wds-space-300) !important;
        }
      }
    }

    input {
      font-size: 20px !important;
      @include mobile-only() {
        font-size: 16px !important;
      }
    }
    ::placeholder {
      font-size: 20px;
      @include mobile-only() {
        font-size: 16px;
      }
    }
  }

  &.homepageNewDesign {
    width: 600px;
    @include mobile-only() {
      width: 100%;
    }
    box-sizing: border-box;
    height: 58px;
    .searchBar {
      [data-input-parent='true'] {
        > div {
          height: 58px;
          > div {
            padding: 0 14.5px !important;
          }
        }
      }

      input {
        font-size: 16px !important;
        padding-inline-start: 4px !important;
      }
      ::placeholder {
        font-size: 16px;
      }
    }
  }
}
