@import '../../../styles/variables.module.scss';

.searchBarModal {
  width: 470px;
  .searchBarModalCTA {
    border-radius: 30px !important;
    * {
      cursor: pointer;
    }
  }

  @include small-screen() {
    width: 400px;
  }

  @include mobile-only() {
    width: 100%;
  }
}

.searchBarModalLayout {
  [class$='LayoutBlocksHeader__innerContent'] {
    @include mobile-only() {
      padding: var(--wds-space-400) var(--wds-space-600) var(--wds-space-300)
        var(--wds-space-400) !important;
    }
  }
  [data-hook='baseModalLayout-content-wrapper'] {
    @include mobile-only() {
      padding-top: 0 !important;
      padding-bottom: var(--wds-space-600) !important;
    }
  }
  @include mobile-only() {
    min-width: unset !important;
    max-width: calc(100vw - 48px) !important;
  }
  > div:first-child {
    @include rtl() {
      right: unset;
      left: -4px;
      top: 2px;
    }
  }
  @include rtl() {
    direction: rtl;
  }
  [data-hook~='baseModalLayout-content-wrapper'] {
    padding-top: var(--wds-space-400);
    padding-bottom: var(--wds-space-400);
  }
  .searchBarModalTitle {
    @include mobile-only() {
      text-align: center;
    }
    span {
      color: var(--wds-color-blue-100);
    }
  }
}
