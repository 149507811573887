@import '../../../../styles/variables.module.scss';
.RoadmapSelectProducts {
  [data-hook='roadmap-select-products'],
  [data-hook='roadmap-select-products-wrapper'] {
    max-width: 270px;
    @include small-screen() {
      max-width: unset;
    }
  }

  .selectProduct {
    max-width: 270;
    width: 100%;
  }
}
