@import './../../../styles/variables.module.scss';

.followArticle {
  max-width: min-content;
  padding-top: var(--wds-space-600);
}

.voteContent {
  text-align: center;
  padding-top: var(--wds-space-100);
  color: var(--wds-color-black-100) !important;

  @include mobile-only() {
    text-align: right;
    max-width: 174px;
  }
}

.followButtonLabel {
  white-space: nowrap;

  &.followButtonLabelDisabled {
    // wix design system mismatch between figma and implementation
    color: var(--wds-color-white-transparent-70);
  }
}
