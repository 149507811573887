@import '../../../../styles/variables.module.scss';

.RoadmapSelectProducts {
  width: 100%;
  max-width: 270px;

  @include small-screen() {
    max-width: unset;
  }

  [data-hook='dropdown-item-empty-state-message'] {
    padding: 42px 0;

    * {
      width: 100%;
    }

    .emptyStateMessage {
      display: block;
      white-space: pre-wrap;
      text-align: center;
      margin: 0 auto;
      width: 100%;
    }

  }

  [data-hook='roadmap-select-products'],
  [data-hook='roadmap-select-products-wrapper'] {
    width: 270px;

    @include small-screen() {
      width: unset;
    }

    @include mobile-only() {
      width: unset;
    }

    [data-hook='dropdown-layout-options'] {
      [data-option-hovered='true'] {
        background-color: var(--wds-color-blue-500);

        [data-hook='list-item-select'] {
          background-color: var(--wds-color-blue-500);
        }
      }
    }
  }

  .selectProductEmptyState {
    .selectProductEmptyStateText {
      color: var(--wds-color-black-200) !important;
    }
  }

  .selectProduct {
    max-width: 270;
    width: 100%;
  }
}
