@import './../../../styles/variables.module.scss';

.requestFeatureOnlyDesktopModal {
  min-width: 200px !important;
  width: calc(100vw - var(--wds-space-600)) !important;
  max-width: calc(100vw - var(--wds-space-600)) !important;

  .onlyDesktopModalContent {
    padding: 40px 30px 24px;
    text-align: center;

    .onlyDesktopModalTitle {
      white-space: pre;
    }

    .onlyDesktopModalButton {
      width: min-content;
      margin: 0 auto;
      margin-top: var(--wds-space-200);
    }
  }

  svg[data-hook='close-large'] {
    width: 14px;
    height: 14px;
  }
}
