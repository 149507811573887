@import '../../styles/variables.module.scss';

.wrapper {
  direction: ltr;
  --sub-header-size: 78px;
  --breadcrumbs-size: 54px;
  --header-size: 72px;

  @include mobile-only() {
    --sub-header-size: 68px;
    --breadcrumbs-size: 43px;
  }

  @include small-screen {
    --breadcrumbs-size: 43px;
  }

  @include rtl() {
    direction: rtl;
  }
}
