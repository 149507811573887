@import '../../styles/variables.module.scss';

.optInMessageModal {
  padding-top: var(--wds-space-100);
}

.mobileModalTitle {
  display: block;
  padding-right: 48px;
}
