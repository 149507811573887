@import './../../../styles/variables.scss';

.stepsWrapper {
  position: relative;
  z-index: 2;
  list-style: none;
  padding-inline-start: unset;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 3.5px;

  &.widget {
    z-index: auto;

    li {
      padding-top: 0px;

      .lastStepClear,
      .activeStepIcon {
        z-index: auto;
      }
    }
  }

  .stepsLine {
    position: absolute;
    top: 12px;
    left: 2.5px;
    background-color: $D50;
    content: '';
    width: 1px;
    height: calc(100% - 24px);

    @include rtl() {
      right: 6.5px;
      left: unset;
    }
  }

  li.level-2 {
    padding-inline-start: 18px;
  }

  li.level-3 {
    padding-inline-start: 21px;
  }

  li.level-4 {
    padding-inline-start: 36px;
  }

  li.level-5 {
    padding-inline-start: 51px;
  }

  li {
    margin-bottom: 9px;
    padding-top: 2px;
    position: relative;
    padding-inline-end: 0px;
    cursor: pointer;
    max-width: 379px;

    &:last-of-type {
      margin-bottom: 0px;
    }

    .stepLinkText {
      font-size: 14px;
      line-height: 18px;
      color: $D10;
    }

    .stepLinkTextActive {
      font-size: 14px;
      line-height: 18px;
      color: $LINK_COLOR;
      font-weight: 500;
    }

    &:hover, &:focus {
      .stepBullet {
        border-color: $DARK_LINK_COLOR_HOVER;
        background-color: $B50;
      }

      .activeStepBullet {
        border-color: $LINK_COLOR_HOVER;
        background-color: $B50;

        .activeIndicator {
          background-color: $LINK_COLOR_HOVER;
        }
      }

      .stepLinkTextActive {
        color: $LINK_COLOR_HOVER;
      }

      .stepLinkText {
        color: $DARK_LINK_COLOR_HOVER;
      }

      &:active {
        .stepBullet {
          border-color: $LINK_COLOR_ACTIVE;
          background-color: $B50;
        }

        .activeStepBullet {
          border-color: $LINK_COLOR_ACTIVE;
          background-color: $B50;

          .activeIndicator {
            background-color: $LINK_COLOR_ACTIVE;
          }
        }

        .stepLinkTextActive,
        .stepLinkText {
          color: $LINK_COLOR_ACTIVE;
        }
      }
    }

    .stepBullet {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: $D80;
      border: solid 1px $D50;
      position: absolute;
      overflow: visible;
      box-sizing: border-box;
      top: 6px;
      left: -3px;

      @include rtl() {
        left: unset;
        right: 1px;
      }

      .activeIndicator {
        display: none;
      }

      &.activeStepBullet {
        border-color: $B10;

        .activeIndicator {
          display: block;
          box-sizing: border-box;
          position: absolute;
          top: 1px;
          border-radius: 50%;
          height: 8px;
          width: 8px;
          content: '';
          left: 1px;
          background-color: $B10;
        }
      }
    }

    .lastStepClear {
      position: absolute;
      top: calc(18px);
      left: -1px;
      z-index: 2;
      width: 8px;
      height: calc(100% - 12px);
      background-color: $D80;
    }

    .activeStepIcon {
      position: absolute;
      top: 8px;
      z-index: 1;
      left: -1px;

      @include rtl() {
        right: 1px;
        left: unset;
        transform: rotateY(180deg);
      }
    }

    &:last-of-type {
      .hideLine {
        position: absolute;
        content: '';
        width: 5px;
        background-color: #fff;
        bottom: 0;
        top: 13px;
        left: 7px;

        @include rtl() {
          right: 7px;
          left: unset;
        }
      }
    }
  }
}