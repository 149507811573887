@import '../../../../styles/variables.module.scss';

.tagsWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  .statusTag {
    margin-right: var(--wds-space-100);
    color: var(--wds-color-black-100);
  }
  .etaTag {
    padding: 0 12px;
    background-color: var(--wds-color-black-600) !important;
  }
  .etaBody,
  .statusBody {
    display: flex;
    align-items: center;
    .resolutionText {
      color: var(--wds-color-black-100) !important;
    }
  }
  .timeIcon {
    padding-right: 7px;
  }
}

.mobileFooter {
  .mobileTagsWrapper {
    .statusBody {
      margin-right: var(--wds-space-100);
    }
    .spacingDot {
      margin: 0 var(--wds-space-100);
      color: var(--wds-color-black-100) !important;
    }
  }

  .statusText {
    &.planned {
      color: var(--wds-color-yellow-50) !important;
    }
    &.released {
      color: var(--wds-color-green-50) !important;
    }
    &.in-dev {
      color: var(--wds-color-purple-100) !important;
    }
    &.pre-launch {
      color: #ff8e8c !important;
    }

    &.collecting-votes {
      color: var(--wds-color-black-300) !important;
    }
  }
}
